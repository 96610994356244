
export default{
    connection :    null,
    redirect: false,
    async connect() {
        this.connection = await require("socket.io-client")(  this.services.http.defaults.baseURL ,
                                                    {   reconnection: true,
                                                        transportOptions: {
                                                            polling: {
                                                                extraHeaders: {
                                                                    uuid: this.services.$router.currentRoute.query.id,
                                                                }
                                                            }
                                                        }
                                                    }
                            );
        this.connection.on( "disconnect", () => this.disconnect() );
    },

    disconnect() {
        console.log( "Disconnecting");
        if( this.redirect ) { return; }
        if( this.services.menu_data.state.session_closed_redirect ) {
            this.services.http.redirect( this.services.menu_data.state.session_closed_redirect );
            return this.services.systemoverlay.open( "Session Closed.<br>Redirecting.")
        }
        this.services.systemoverlay.open( "This Sessions does no longer exist.")
    },
    send( message, data ) {
        this.connection.emit( "message_for_installation", {message: message, data: data});
    }
}