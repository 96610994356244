<template>
   <button :class="arrow_button_class"
                  :style="[arrow_style(direction), color_style, opacity ]"
                  @pointerdown="press(direction, true)"
                  @pointerup="press(direction, false)"> </button>
</template>

<script>
export default {
    data: () => ( {
        value: false,
    }),
    props: ["direction"],
    computed: {
        arrow_button_class() {
            return `m-2 rounded-2 transition duration-300 ease-in-out`
        },
        color_style() {
            const color = this.$service.color.get_item_color( this.$service.overlay.state.dataset, "primary_color" );
            return this.value ? this.$service.color.background_style( color ) : {"background-color" : "grey"};
        },
            opacity() {
                return  {"filter": this.value ? "opacity(100%)" : "opacity(70%)" }
            },
    },
    methods: {
        press(direction, value) {
            this.value = value;
            this.$service.socket.send(this.$service.overlay.state.message, {"event" : direction,
                                                                            "value" : value})
           
        },
        arrow_style( direction ) {
            const width =   direction == "up" || direction == "down" ? "20vh": "10vh";
            const height = direction == "up" || direction == "down" ? "10vh": "20vh";
            return {
                "width"         : width,
                "height"        : height,
                "mask-image"    : `url('/arrow_${direction}.png')`,
                "mask-size"     : "100% 100%",
                "background-blend-mode" : "multiply",    
            }
        }
    }
}
</script>

<style>

</style>