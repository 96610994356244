export default {
    state: {
        active      : false,
        type        : null,
        dataset     : null,
        message     : ""
    },
    close() {
        this.services.socket.send( this.state.message, {    event: "overlay",
                                                            value: false  })
        this.update_store( {active: false,
                            message: '' });
       
       
    },
    open(type, message, dataset = null) {
        if (this.state.message ){
            this.services.socket.send( this.state.message, {    event: "overlay",
                                                                value: true  });
        }

        this.services.socket.send( message, {   event: "open",
                                                value: true  });
                                       
        this.update_store ( {
            active: true,
            type : type,
            dataset: dataset,
            message: message,
        })
    }
}