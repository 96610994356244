export default {
    state: {
        loaded: false,
    },

    set_data( dataset ) {
        this.update_store( dataset );
        this.update_store( {loaded: true });
    },

    async fetch_example_data() {
        this.set_data( await this.services.http.get("/menu-types/Neurona").then(response => response.data) );
    },
    async fetch_data() {
        this.set_data( 
            await this.services.http.get(`/socketmanager/installations/${this.services.$router.currentRoute.query.id}`)
                .then(response => response.data) 
                .catch(() => this.services.socket.disconnect())
        );
    },
    get_header_image_url(){
        return this.services.http.prefix_url( this.state.headerimage ? this.state.headerimage.url : "");
    },
    get_background_image_url(){
        return this.services.http.prefix_url( this.state.Backgroundimage ? this.state.Backgroundimage.url : "");
    },
}