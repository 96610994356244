<template>
   <div  :class="$service.style_class.header()"
                :style="$service.style_set.header()">
                <slot/>
   </div>
</template>

<script>
export default {

}
</script>

<style>

</style>