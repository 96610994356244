
const axios = require("axios");

axios.defaults.baseURL = process.env.NODE_ENV == 'production' ? window.location.origin : "http://192.168.0.8:1337";
axios.prefix_url = function( url ) {
    const prefix = !url.startsWith("http") ? this.defaults.baseURL : '';
    return  prefix + url;
};

axios.get_image_url = function ( image_object ) {
    return image_object ? this.prefix_url( image_object.url ) : '';
};

axios.redirect = function( url ) {
    if (url) { window.location.href = url; }
};

export default axios;