function store_mutator(state, data) {
    Object.assign( state, data.data);
}

function service_update(data) {
    this.services.$store.commit(`${this.name}/update`, {name: this.name, data: data});
}

export default {
    install(Vue, options) { 
        
        let target_modules = [];
        let data_export = {
            $vue: Vue,
        };
        for( let key in options) {
          data_export[ `$${key}`] = options[key];  
        }
                

        const context = require.context('.', true, /.js/);
        console.log( context );
        context.keys().forEach((filename) => {
            const module_path_list = filename.split('/')
            if (module_path_list.length < 3) { return; }
            const module_name = module_path_list[1];
            target_modules.push( module_name );
        });
        
        

        for (const module_name of target_modules) {
            data_export[module_name] = Object.assign( { __init__ : () => null }, require( `./${module_name}`).default);
            data_export[module_name].__init__();
            data_export[module_name].services = data_export;

           
           
            if (options.store && data_export[module_name].state != null) {
                data_export[module_name].name = module_name;
                data_export[module_name].update_store = service_update;
                data_export.$store.registerModule( module_name, {state : data_export[module_name].state,
                                                                mutations: { update : store_mutator },
                                                                namespaced : true,} );

                data_export[module_name].state = data_export.$store.state[module_name];
            }
        }
        Vue.prototype.$service = data_export;
    }
};