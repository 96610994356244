<template>
  <div class="flex-1 rounded-lg m-2"/>
</template>

<script>
export default {

}
</script>

<style>

</style>