export default {
    default_colors: {
        "primary_color"   : "blue-500",
        "secondary_color" : "grey-500"
    },
    get_item_color( item_object, default_color = "secondary_color" ) {
        return  item_object.color ||
                this.get_global_color( default_color )
    },
    offset_color( color_string, offset = 100 ){
        const split_color = color_string.split("-");
        return `${split_color[0]}-${parseInt(split_color[1]) + offset}`
    },
    get_global_color( color_string ) {
        return  this.services.menu_data.state[color_string] ||
                this.default_colors[ color_string ];
    },
    inactive_background : {
        "background-color" : "rgba(0,0,0,0.2)"
    },


    angles : {
        "green" : 120,
        "red"   : 0,
        "blue"  : 240,
        "yellow": 45,
        "magenta" : 315,
        "lightblue" : 180,
        "lime"      : 135,
        get(name, default_color ="0") {
            return this[name] || default_color; 
        } 
    },
    split_colorstring( color_string ) {
        const split_string = color_string.split("-");
        return {
            "name"  : split_string[0],
            "value" : 100 - (parseInt( split_string[1])/10 ),
        }
    },

    colorstring_to_hsl(color_string) {
        const colorObject = this.split_colorstring( color_string);
        return `hsl(${ this.angles.get(colorObject.name)},100%, ${colorObject.value}%)`;
    },

    background_style( color_string ) {
        return { "background-color"  : this.colorstring_to_hsl( color_string ) };
    },
    text_style( color_string ) {
        return { "color"  : this.colorstring_to_hsl( color_string ) };
    },
    border_style( color_string ) {
        return { "border-color"  : this.colorstring_to_hsl( color_string ) };
    }
    
}