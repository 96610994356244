<template>
  <div :style="style" class="flex" ref="row">
     <interactable 
        v-for="interactable in row_data.interactables" 
        :key="interactable.name" 
        :interactable_data="interactable"/>
  </div>
</template>

<script>
import Interactable from './Interactable.vue'

export default {
  components: {Interactable},
    props: ["row_data"],
    data: () => ({
      width: 0,
    }),
    mounted() {
      this.width = this.$refs.row.clientWidth;
    },
    computed: {
        style () {
          console.log( this.width );
            return {
                width           : "100%",
                height          :  `${this.width / this.row_data.interactables.length}px`, 
            }
        }
    }
}
</script>

<style>

</style>