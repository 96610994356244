<template>
   <button :class="default_button_class"
            :style="[color_style, opacity, size]"
                  @pointerdown="down"
                  @pointerup="up"
                 >OK</button>
</template>

<script>
export default {
    data: () => ( {
        value: false,
    }),
    methods: {
        down() {
            this.$service.socket.send(this.$service.overlay.state.message, {"event" : "ok",
                                                                            "value" : this.value})
            this.value = true;
        },
        up() {
           this.$service.socket.send(this.$service.overlay.state.message, {"event" : "ok",
                                                                            "value" : this.value})
            this.value = false;
        },
    },
    computed : {
            default_button_class() {
                return `m-6 rounded-full 
                        border-4
                        transition duration-100 ease-in-out
                        text-3xl font-mono font-black` 
            },
            color_style() {
                const color = this.$service.color.get_item_color( this.$service.overlay.state.dataset, "primary_color" );
                return this.value ? this.$service.color.background_style( color ) : {"background-color" : "grey"};
             },
            opacity() {
                return  {"filter": this.value ? "opacity(100%)" : "opacity(80%)" }
            },
            size() {
                 return { "width" : "18vh", "height": "18vh" }
            },
        },
    }
</script>

<style>

</style>