export default {
    container() {
        return { "min-height"            : "10vh"
        }
    },
    app() {
        return Object.assign(
            {
            "background-image"      : `url(" ${this.services.menu_data.get_background_image_url() } ")`,
            "background-size"       : "cover",
            "background-repeat"     : "no-repeat",
            "background-position"   : "center",
            "width"                 : "100vw", 
            "min-height"            : "100vh",
            "z-index"               : "1" },
            this.services.color.background_style( this.services.color.get_global_color("secondary_color") ),
            this.services.color.text_style(this.services.color.get_global_color("primary_color")),
        );
    },
    overlay() {
        return Object.assign( this.app(),{
            "position"              : "fixed",
            "z-index"               : "2",
        })
    },
    header() {
        return { "background-image"      : `url(" ${this.services.menu_data.get_header_image_url() } ")`,
                 "background-size"       : "contain",
                 "background-repeat"     : "no-repeat",
                 "background-position"   : "center",
                 "height"                : "5vh"
        }
    },
    button_background( fileobject ) {
        return { 
            "background-image"  : `url("${this.services.http.get_image_url(fileobject)}")`,
            "background-size"   : "contain",
            "background-repeat"  : "no-repeat",
            "width"             : "90%",
            "height"            : "90%",
            "background-position": "center"  
        };
    }
}