<template>
  <menu-container :menu="$service.overlay.state.dataset"/>
</template>

<script>
import MenuContainer from '../Layout/MenuContainer.vue'
export default {
  components: { MenuContainer },

}
</script>

<style>

</style>