<template>
  <div :style="$service.style_set.app()" 
       :class="$service.style_class.app()">
     <overlay/>
     <system-overlay/>

    <div  v-if="$service.menu_data.state.loaded"
          style="max-width: 1100px;"
          class="flex flex-col flex-grow overflow-hidden">
     
        <app-header/>
        <menu-container :menu="$service.menu_data.state.menu">
      </menu-container>
    </div>
    
  </div>

</template>

<script>
import AppHeader from './components/Layout/AppHeader.vue'
import MenuContainer from './components/Layout/MenuContainer.vue'
import Overlay from './components/Overlay/Overlay.vue';
import SystemOverlay from './components/SystemOverlay/SystemOverlay.vue';



export default {
  async mounted(){
    this.$service.systemoverlay.open( "Connecting.", true);
    await this.$service.menu_data.fetch_data();
    await this.$service.socket.connect();
    this.$service.systemoverlay.close();
  },
  components: { MenuContainer, AppHeader,Overlay, SystemOverlay },
}
</script>

<style>

</style>