<template>
  <interactable-button :custom_event="true"  :data="data" @click="click"/>
</template>

<script>
import InteractableButton from './InteractableButton.vue'
export default {
  components: { InteractableButton },
    props: ["data"],
    methods: {
        click() {
            this.$service.overlay.open( "navigation", this.data.message, this.data );
        }
    }
}
</script>

<style>

</style>