<template>
  <div class="flex-grow">
      <div  :style="[ height, border_color ]"
            class="rounded-full flex-grow backdrop-filter backdrop-blur border-2  m-10 flex"
            ref="outer_circle" id="outer_circle">


                <div    :style="[style, border_color]"
                        class="rounded-full flex-grow backdrop-filter backdrop-blur border-2  m-auto"
                        ref="inner_circle" id="inner_circle"
                @touchmove="move"
                @touchstart="start"
                @touchend="end"
            />
           
      </div>
  </div>
 
</template>

<script>
export default {
    data: () => ( {
        nubblesize : 0.3,
        mounted : false,
        offset: { x: 0, y: 0},
        old : {x: 0, y: 0},
        clamped_vector      : {x : 0, y : 0},
        normalized_vector   : {x : 0, y : 0},
        
    }),
    mounted() {
        this.mounted = true;
    },
    computed: {
        border_color() {
            return this.$service.color.border_style(this.$service.color.get_global_color("primary_color"));
        },
        style() {
            return {
                "max-width"     : `${Math.round(this.nubblesize * 100)}%`,
                "height"    : `${Math.round(this.nubblesize * 100)}%`,
                transform   : `translate(${this.clamped_vector.x}px, ${this.clamped_vector.y}px)`
            }
        },
        height() {
            return {'height': `${this.width}px` }
        },
        width() {
            return this.mounted ? this.$refs.outer_circle.clientWidth : 0;
        },
        clamp_range () {
            return this.width/2 * (1-this.nubblesize)
        }
    },

    methods: {
        clamp_vector() {
            const length = Math.sqrt( this.offset.x**2 + this.offset.y**2 ) + 1;
            const clamped_length = this.clamp( length , 0, this.clamp_range );
          this.normalized_vector  = { x: this.offset.x / length, 
                                        y: this.offset.y / length};

            this.clamped_vector     = { x: this.normalized_vector.x * clamped_length,
                                        y: this.normalized_vector.y * clamped_length };
          
        },
        clamp( value, min, max) {
            return Math.min(Math.max(value, min), max); 
        },
        start(event) {
            this.offset.x = 0;
            this.offset.y= 0;
            this.old.x = event.touches[0].pageX;
            this.old.y = event.touches[0].pageY;
            this.$service.socket.send(this.$service.overlay.state.message, {"event" : "in_use",
                                                                            "value" :true})
        },
        move(event) {
            this.offset.x -= this.old.x-event.touches[0].pageX;
            this.offset.y -= this.old.y-event.touches[0].pageY;
            this.old.x = event.touches[0].pageX;
            this.old.y = event.touches[0].pageY;
            this.clamp_vector();
            this.$service.socket.send(this.$service.overlay.state.message, {"event" : "handle",
                                                                            "value" : this.normalized_vector})
        },
        end() {
            this.offset.x = 0;
            this.offset.y = 0;
            this.clamp_vector();
            this.$service.socket.send(this.$service.overlay.state.message, {"event" : "in_use",
                                                                            "value" : false })
        }
    }
}
</script>

<style>

</style>