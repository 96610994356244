export default {
    button() {
        return "flex-1 rounded-lg m-2 flex items-center justify-center backdrop-filter backdrop-blur";
    },
    button_transition() {
        return "transition duration-200 ease-in-out";
    },
    container() {
        return ["shadow-sm", "rounded-lg", "m-8"]
    },
    app() {
        return [`flex justify-center `];
    },
    overlay() {
        return this.app().concat( 
            ["flex flex-col transition duration-500 ease-in-out"]
        );
    },
    header() {
        return this.container().concat( 
            ["flex justify-center"]
        );
    }
}