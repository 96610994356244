<template>
  <div :class="class_style"
       :style="set_style">
               <div style="width: 100%; max-height: 5vh"
                    class="flex flex-grow items-center m-3 p-3">
        <button @click="$service.overlay.close()" class="arrow left"></button>
        </div>

       <component :is="component" class="flex-grow"/>
       </div>
</template>

<script>
import OverlayJoystick from './OverlayJoystick.vue';

import OverlayNavigation from './OverlayNavigation.vue';
import OverlaySubmenu from './OverlaySubmenu.vue';
export default {
  components: { OverlayNavigation, OverlaySubmenu, OverlayJoystick},
  data : () => ( {
        available_components: {
            "navigation"    : OverlayNavigation,
            "submenu"       : OverlaySubmenu,
            "joystick"      : OverlayJoystick,
        }
    }),
    computed: {
        component() {
            return this.available_components[this.$service.overlay.state.type]
        },
        class_style() {
            return this.$service.style_class.overlay();
        },
        set_style() {
            const styleset = this.$service.style_set.overlay()
            styleset.transform = this.$service.overlay.state.active ? "translate(0%)" : "translate(100%)";
            return styleset;
        }
    }
}
</script>

<style>
.arrow {
  border: solid white;
  border-width: 0 1vh 1vh 0;
  padding: 1vh;
  display: block;
}
.left {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}
</style>