<template>
  <component :is="component_type" :data="interactable_data"/>
</template>

<script>
import InteractableButton from './InteractableButton.vue'
import InteractableJoystick from './InteractableJoystick.vue'
import InteractableNavigation from './InteractableNavigation.vue'
import InteractableNull from './InteractableNull.vue'
import InteractableSpacer from './InteractableSpacer.vue'
import InteractableSubmenu from './InteractableSubmenu.vue'
import InteractableToggle from './InteractableToggle.vue'
export default {
  components: { InteractableButton, InteractableNull, InteractableSpacer, InteractableNavigation, InteractableSubmenu, InteractableToggle, InteractableJoystick },
    props: ["interactable_data"],
    data : () => ( {
        available_components: {
            "button" : InteractableButton,
            "null"   : InteractableNull,
            "spacer" : InteractableSpacer,
            "navigation" : InteractableNavigation,
            "submenu"     : InteractableSubmenu, 
            "toggle"      : InteractableToggle,
            "joystick"      : InteractableJoystick,
        }
    }),
    computed : {
        component_type() {
            return this.available_components[ this.interactable_data.type ];
        }
    }
}
</script>

<style>

</style>