<template>
   <div :class="class_style"
       :style="set_style">
        <div class="m-auto">
        <div v-if="$service.systemoverlay.state.loader"
                class="bg-blue-200 rounded-full animate-ping m-11 flex items-center justify-center" style="width: 6vh; height: 6vh">
                <div class="bg-black m-auto rounded-full" style="width: 90%; height: 90%"/>
        </div>

        <div class="text-center flex-grow" 
                v-html="$service.systemoverlay.state.message"/>
        </div>
   </div>
</template>

<script>
export default {
    computed: {
        set_style() {
            return {
                "width"         : "100vw", 
                "min-height"    : "100vh",
                "position"      : "fixed",
                "display"       : this.$service.systemoverlay.state.display ? "flex" : "none",
                "z-index"       : 3.    
            }
        },
        class_style() {
            return "bg-black flex text-white items-center flex-col p-auto"
        }
    }
}
</script>

<style>

</style>