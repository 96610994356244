<template>
  <div :class="$service.style_class.container()">
    <interactable-row v-for="row in menu.rows" :key="row.id" :row_data="row"/>
  </div>
</template>

<script>
import InteractableRow from '../Interactables/InteractableRow.vue'
export default {
  components: { InteractableRow },
  props: ["menu"],
  mounted() {
    console.log( this.menu );
  }
}
</script>

<style>

</style>