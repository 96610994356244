export default {
    state: {
        display: false,
        message : "System Message Here",
        loader  : true,
    },
    open( message, loading = false) {
        this.update_store( { display: true, message: message, loader: loading});
    },
    close() {
        this.update_store({display: false})
    }
}