<template>
   <div  :class="[$service.style_class.button(),  $service.style_class.button_transition(), 'overflow-clip']"
         :style="[color_style]"
         
            
            @pointerdown="click('touch')"
            @pointerup="end('touch')"

            >
             <div   v-if="data.icon"
                    :style="$service.style_set.button_background( data.icon )"/>
            <div v-else class="text-xl uppercase font-mono">{{data.label}}</div>
   </div>
</template>

<script>
export default {
    props: ["data", "custom_event"],
    data: () => ( {
        value: false,
    }),
    methods: {
        click(source) {
            this.$emit("click", this.data);
            this.value = true;
            if (this.custom_event) { return; }
           
            this.$service.socket.send( this.data.message, { event: "click", 
                                                            value: true,
                                                            source: source});
        },
        end(source) {
            this.$emit("end", this.data);
             this.value = false;
            if (this.custom_event) { return; }
            this.$service.socket.send( this.data.message, { event: "click", 
                                                            value: false,
                                                            source: source});
        }
    },
    computed: {
        color_style() { 
            const color = this.$service.color.get_item_color( this.data, "primary_color" );
            return this.value ? this.$service.color.background_style( color ) : this.$service.color.inactive_background;
        },
    }
}
</script>

<style>

</style>