<template>
  <div class="flex items-center flex-col">     
        <div style="height: 20vh"/>

        <div>
          <arrow-button direction="up"/>
        </div>
        <div class="flex">
         <arrow-button direction="left"/>

          <ok-button/>

          <arrow-button direction="right"/>
        </div>
        <div >
         <arrow-button direction="down"/>
        </div>

        
  </div>
</template>

<script>
import ArrowButton from './NavigationElements/ArrowButton.vue'
import OkButton from './NavigationElements/OkButton.vue'
export default {
  components: { OkButton, ArrowButton }
    
      }

</script>

<style>

</style>