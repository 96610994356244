<template>
     <div  :class="[$service.style_class.button(),  $service.style_class.button_transition()]"
            :style="[color_style]"
            @click="click"
            >
             <div   v-if="data.icon"
                    :style="$service.style_set.button_background( data.icon )"/>
            <div v-else class="text-xl uppercase font-mono">{{data.label}}</div>
   </div>
</template>

<script>
export default {
    props: ["data", "custom_event"],
    data: () => ( {
        value: false,
    }),
    methods: {
        click() {
            this.value = !this.value;
            if (this.custom_event) { return; }
            this.$service.socket.send( this.data.message, { event: "toggle", 
                                                            value: this.value });
        }
    },
    computed: {
        color_style() { 
            const color = this.$service.color.get_item_color( this.data, "primary_color" );
           
            return this.value ? this.$service.color.background_style( color ) : this.$service.color.inactive_background;
        },
    }
}
</script>

<style>

</style>